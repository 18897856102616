import Loading from '@cannect/components/Loading'
import { useAuth } from '@cannect/hooks/AuthContext'
import { AnimatePresence, motion } from 'framer-motion'

import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { removePahtFrom } from '@cannect/hooks/usePathsRedirect'
import { setUser } from '@cannect/hooks/useSessionUser'
import { useTokenApi } from '@cannect/hooks/useTokenApi'
import { RegisterSuccess } from '@cannect/pages/Registration/components/Forms/hooks/useRegistrationForm'
import api, { defaultsHeadersAxios } from '@cannect/services/api'
import { authenticate } from '@cannect/usecases/user'
import { clearMask, formatCpf, formatPhone } from '@cannect/utils/formatters'
import React, { useState } from 'react'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import { useHistory } from 'react-router'
import { z } from 'zod'
import SupplementsModalLogin from './Modal'
import * as Styles from './styles'

interface SupplementsLoginProps {
  isOpen: boolean
  setOpenModal: (isOpen: boolean) => void
  keyParamsSearchUrl?: string
}

const emailSchema = z.string().email('E-mail inválido')
const cpfSchema = z.string()
const phoneSchema = z.string()
const passwordSchema = z
  .string()
  .min(6, 'A senha deve ter pelo menos 6 caracteres.')
  .regex(/[A-Z]/, 'A senha deve ter pelo menos um caractere maiúsculo.')
  .regex(/\d/, 'A senha deve conter pelo menos um dígito.')
  .regex(/[!@#$%^&*(),.?":{}|<>]/, 'A senha deve conter pelo menos um símbolo especial.')

export default function SupplementsLogin({ isOpen, setOpenModal, keyParamsSearchUrl }: SupplementsLoginProps) {
  const [form, setForm] = useState<'login' | 'register'>('login')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const { setTokenApi } = useTokenApi()
  const { push } = useHistory()
  const { getRoles } = useAuth()
  const [errorApi, setErrorApi] = useState('')
  const isMobile = useMediaQuery('(max-width: 600px)')

  const formVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 }
  }

  const changeForm = (form: 'login' | 'register') => {
    setForm(form)
    setErrors({})
    setEmail('')
    setPassword('')
    setConfirmPassword('')
    setName('')
    setCpf('')
    setPhone('')
    setErrorApi('')
  }

  const validateInputs = () => {
    const newErrors: Record<string, string> = {}

    try {
      emailSchema.parse(email)
    } catch {
      newErrors.email = 'E-mail inválido'
    }

    if (form === 'register') {
      try {
        passwordSchema.parse(password)
      } catch {
        newErrors.password = 'A senha deve ter pelo menos 6 caracteres'
      }

      if (password !== confirmPassword) {
        newErrors.confirmPassword = 'As senhas não conferem'
      }

      try {
        cpfSchema.parse(clearMask(cpf))
      } catch {
        newErrors.cpf = 'CPF inválido'
      }

      try {
        phoneSchema.parse(clearMask(phone))
      } catch {
        newErrors.phone = 'Telefone inválido'
      }
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!validateInputs()) return
    setLoading(true)
    try {
      const response = await authenticate({ email, password })

      setTokenApi(response.token)
      defaultsHeadersAxios(response.token)
      await setUser(response.user)
      getRoles()

      const pageToRedirect = `/produtos/1?supplier_id=27&${keyParamsSearchUrl}`

      push(pageToRedirect)
      await removePahtFrom()
    } catch {
      setErrorApi('E-mail ou senha incorretos')
    } finally {
      setLoading(false)
    }
  }

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!validateInputs()) return
    setLoading(true)
    try {
      const {
        data: { token, user }
      } = await api.post<RegisterSuccess>('/register', {
        name,
        preferred_name: name,
        email,
        cpf: clearMask(cpf),
        password,
        phone: clearMask(phone),
        whatsapp: clearMask(phone),
        registration_type: 1,
        nationality: 'brasileira',
        rne: ''
      })

      setTokenApi(token)
      defaultsHeadersAxios(token)
      await setUser(user)
      getRoles()

      const pageToRedirect = `/produtos/1?supplier_id=27&${keyParamsSearchUrl}`

      push(pageToRedirect)
    } catch {
      setErrorApi('Erro ao realizar cadastro verifique os dados informados')
    } finally {
      setLoading(false)
    }
  }

  return (
    <SupplementsModalLogin isOpen={isOpen} setOpenModal={setOpenModal}>
      <Loading loading={loading} />

      <Styles.Container isMobile={isMobile}>
        <Styles.ButtonHeaders isMobile={isMobile}>
          <Styles.ButtonLogin active={form === 'login'} onClick={() => changeForm('login')} isMobile={isMobile}>
            Login
          </Styles.ButtonLogin>
          <Styles.ButtonRegister
            active={form === 'register'}
            onClick={() => changeForm('register')}
            isMobile={isMobile}>
            Cadastro
          </Styles.ButtonRegister>
        </Styles.ButtonHeaders>

        <Styles.ContainerForms isMobile={isMobile}>
          <AnimatePresence mode="wait">
            {form === 'login' && (
              <motion.div
                key="login"
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                transition={{ duration: 0.3 }}>
                <Styles.FormLogin isMobile={isMobile}>
                  <Styles.Input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(event: any) => setEmail(event.target.value)}
                  />
                  {errors.email && <Styles.ErrorMessage>{errors.email}</Styles.ErrorMessage>}

                  <Styles.InputGroup>
                    <Styles.Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Senha"
                      value={password}
                      onChange={(event: any) => setPassword(event.target.value)}
                    />
                    <Styles.TogglePassword
                      onClick={(event) => {
                        event.preventDefault()
                        setShowPassword(!showPassword)
                      }}>
                      {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                    </Styles.TogglePassword>
                  </Styles.InputGroup>
                  {errors.password && <Styles.ErrorMessage>{errors.password}</Styles.ErrorMessage>}
                  {errorApi && <Styles.ErrorMessage>{errorApi}</Styles.ErrorMessage>}
                  <Styles.ButtonFormLogin onClick={(e) => handleLogin(e)} disabled={loading}>
                    {loading ? 'Carregando...' : 'Entrar'}
                  </Styles.ButtonFormLogin>
                </Styles.FormLogin>
              </motion.div>
            )}

            {form === 'register' && (
              <motion.div
                key="register"
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                transition={{ duration: 0.3 }}>
                <Styles.FormLogin isMobile={isMobile}>
                  <Styles.Input
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(event: any) => setName(event.target.value)}
                  />

                  <Styles.Input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={(event: any) => setEmail(event.target.value)}
                  />
                  {errors.email && <Styles.ErrorMessage>{errors.email}</Styles.ErrorMessage>}

                  <Styles.InputGroup>
                    <Styles.Input
                      type="text"
                      placeholder="CPF"
                      value={formatCpf(cpf)}
                      onChange={(event: any) => setCpf(event.target.value)}
                      width="50%"
                    />
                    {errors.cpf && <Styles.ErrorMessage>{errors.cpf}</Styles.ErrorMessage>}

                    <Styles.Input
                      type="text"
                      placeholder="Telefone"
                      value={formatPhone(phone)}
                      onChange={(event: any) => setPhone(event.target.value)}
                      width="50%"
                    />
                    {errors.phone && <Styles.ErrorMessage>{errors.phone}</Styles.ErrorMessage>}
                  </Styles.InputGroup>

                  <Styles.InputGroup>
                    <Styles.Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Senha"
                      value={password}
                      onChange={(event: any) => setPassword(event.target.value)}
                    />
                    <Styles.TogglePassword
                      onClick={(event) => {
                        event.preventDefault()
                        setShowPassword(!showPassword)
                      }}>
                      {showPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                    </Styles.TogglePassword>
                  </Styles.InputGroup>
                  {errors.password && <Styles.ErrorMessage>{errors.password}</Styles.ErrorMessage>}

                  <Styles.InputGroup>
                    <Styles.Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirmar Senha"
                      value={confirmPassword}
                      onChange={(event: any) => setConfirmPassword(event.target.value)}
                    />
                    <Styles.TogglePassword
                      onClick={(event) => {
                        event.preventDefault()
                        setShowConfirmPassword(!showConfirmPassword)
                      }}>
                      {showConfirmPassword ? <MdOutlineVisibilityOff /> : <MdOutlineVisibility />}
                    </Styles.TogglePassword>
                  </Styles.InputGroup>
                  {errors.confirmPassword && <Styles.ErrorMessage>{errors.confirmPassword}</Styles.ErrorMessage>}
                  {errorApi && <Styles.ErrorMessage>{errorApi}</Styles.ErrorMessage>}

                  <Styles.ButtonFormLogin onClick={(e) => handleRegister(e)} disabled={loading}>
                    {loading ? 'Carregando...' : 'Cadastrar'}
                  </Styles.ButtonFormLogin>
                </Styles.FormLogin>
              </motion.div>
            )}
          </AnimatePresence>
        </Styles.ContainerForms>
      </Styles.Container>
    </SupplementsModalLogin>
  )
}
