import styled, { css, keyframes } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

interface PriceProps {
  withDiscount?: boolean
}

interface WrapperProps {
  withAuth?: boolean
}

interface ButtonProductProps {
  isLoading?: boolean
  bgColor?: 'green' | 'orange'
  isSupplements?: boolean
}

interface DetailsTrackProps {
  isEmpty?: boolean
}

const rotate = keyframes`
  to {
    transform: rotateZ(360deg);
  }
`

export const ProductSabor = styled.span<any>`
  ${({ bgColor, fontColor }) => css`
    background: ${bgColor === 'green' ? '#9ae19d' : '#ffe5b4'};
    color: ${fontColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 5px;
    font-family: 'Lato', sans-serif;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;

    @media (max-width: 900px) {
      margin-bottom: 15px;
    }

    .load-box {
      display: flex;
      align-items: center;
      margin-right: 8px; /* Espaço entre o loader e o texto */
    }
  `}
`

export const BadgeProduct = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 45px;
  height: 51px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 15px;
  background: ${temporaryTheme.colors.bgDark};
  font-family: 'Spartan', sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: ${temporaryTheme.colors.white};

  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
  }

  @media (max-width: 900px) {
    width: 35px;
    height: 40px;
    font-size: 9px;
  }
`

export const DetailsContent = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${temporaryTheme.spacing.space2};
    padding: 25px 5px 25px 5px;
  }
`
export const ActionsContent = styled.div`
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const FavoriteBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    cursor: pointer;
    top: 5px;
  }

  @media (max-width: 900px) {
    position: absolute;
    top: 5px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 14px;

  @media (max-width: 900px) {
    width: 35%;
    border-radius: 9px;
  }
`

export const MoreInfo = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  display: none;
  transition: 0.3s;
`

export const ButtonMoreInfo = styled.button`
  all: unset;
  color: ${temporaryTheme.colors.white};
  background: ${temporaryTheme.colors.tertiary};
  font-family: 'Spartan', sans-serif;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 6px;
  padding-top: 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${temporaryTheme.colors.sitePrimary};
  }
`

export const ImageBox = styled.div`
  width: 104px;
  height: 104px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
`
export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s;
`

export const TitleProductBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 50%;
    gap: ${temporaryTheme.spacing.space4};
  }
`

export const TitleProduct = styled.p`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  width: 153px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 9px;

  @media (${MOBILE_WIDTH}) {
    text-align: left;
    width: 100%;
  }
`

export const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${temporaryTheme.spacing.space2};
  margin-bottom: 14px;

  @media (max-width: 900px) {
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
  }
`
export const Price = styled.span<PriceProps>`
  ${({ withDiscount }) => css`
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
    font-size: ${withDiscount ? '10px' : '12px'};
    color: ${withDiscount ? temporaryTheme.colors.red : temporaryTheme.colors.darkGrey};
    text-decoration: ${withDiscount && 'line-through'};

    span {
      font-family: 'Spartan', sans-serif;
      font-weight: 400;
      font-size: 11px;
    }
  `}
`

export const ActionBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;

  .acc {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ButtonProduct = styled.button<ButtonProductProps>`
  ${({ isLoading }) => css`
    padding: 7px;
    border-radius: 5px;
    background-color: #748351;
    min-width: 125px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${temporaryTheme.spacing.space2};
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: ${temporaryTheme.colors.white};
    opacity: ${isLoading ? 0.5 : 1};
    pointer-events: ${!!isLoading && 'none'};

    svg {
      width: 14px;
      height: 14px;
    }

    .load-box {
      display: flex;
      align-items: center;

      svg {
        animation: ${rotate} 2s linear infinite;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  `}

  ${({ isSupplements }) => css`
    opacity: 0.8;
  `}
`

export const DetailsTrack = styled.div<DetailsTrackProps>`
  font-family: 'Spartan', sans-serif;
  font-size: 10px;
  color: #858585;
  text-align: center;
  min-height: 1em; // Ensure consistent height
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      min-height: 1em; // Adjust this value as needed
    `}
`

export const Wrapper = styled.main<WrapperProps>`
  ${({ withAuth }) => css`
    width: 209px;
    height: 380px;
    position: relative;
    border-radius: 15px;
    border: 0.832083px solid #b5b5b5;
    padding: ${temporaryTheme.spacing.space2};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 50px;

    &:hover {
      border-color: ${temporaryTheme.colors.sitePrimary};
      box-shadow: ${temporaryTheme.shadow.shad1};
      ${Image} {
        transform: scale(1.1);
      }

      ${MoreInfo} {
        display: flex;
      }
    }

    ${ImageContainer} {
      padding-top: ${!withAuth && '20px'};
    }

    ${TitleProduct} {
      -webkit-line-clamp: ${!withAuth && 5};
    }

    @media (max-width: 900px) {
      height: auto;
      width: 300px;
    }
  `}
`

interface ButtonAssistantProps {
  prescribedProduct: boolean
}

const assistantModifier = {
  selected: () => css`
    background: transparent;
    border: 1px solid #b5b5b5;
    color: #858585;

    svg {
      color: red;
    }
  `
}

export const ButtonAssistant = styled(ButtonProduct)<ButtonAssistantProps>`
  ${({ prescribedProduct }) => css`
    background: #748351;

    ${!!prescribedProduct && assistantModifier.selected()};
  `}
`
