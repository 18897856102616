import { drCannabisLogo, ecosystem, firstCardImage, secondCardImage } from 'assets/img'
import bannerPartnesVideo from 'assets/video/banner-partnes.mp4'
import { useCartCheckout } from 'hooks/useCartCheckout'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Modal from 'new-components/Modal'
import AccordionPartnes from 'new-components/partnesComponents/AccordionPartnes'
import Card from 'new-components/partnesComponents/Card'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { redirectLink } from 'utils/redirectLink'
import usePageTitle from 'utils/usePageTitle'
import { UTMs } from '../../utils/constants'
import {
  AboutUs,
  AccordionDataPartnes,
  DiferencePillars,
  dr_cannabis,
  education,
  partnesLogos,
  Services
} from './mock-prescribers-home'
import * as Styles from './styles'

interface DataForm {
  name: string
  email: string
  phoneNumber: string
  city: string
  company: string
  interestOn: string
  message?: string
}

export default function Partners() {
  usePageTitle('Parceiros')
  const history = useHistory()
  const { sendDataFormPartnerEmail } = useCartCheckout()

  const [name, setName] = useState('')
  const [email, setEmail] = useState<string>()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [city, setCity] = useState('')
  const [company, setCompany] = useState('')
  const [interestOn, setInterestOn] = useState('')
  const [message, setMessage] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const [dataForm, setDataForm] = useState<DataForm>({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    company: '',
    interestOn: '',
    message: ''
  })
  const redirectLinkCustom = () => {
    window.open('https://atendimento.cannect.life/atendimento-cannect', '_blank', 'noopener')
  }

  const redirectForm = (linkRef: string) => {
    window.open(`https://cannect13369.ac-page.com/atendimento-marcas?${linkRef}=true${UTMs['organic_site_cannect_botao']}`, '_blank', 'noopener')
  }

  const ScrollToForm = () => {
    const element = document.getElementById('sectionForm')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const sendEmail = async () => {
    const { data } = await sendDataFormPartnerEmail(dataForm)

    if (data.success) {
      toast.success('Dados enviados com sucesso! Logo retornaremos o contato.')
      setName('')
      setEmail('')
      setPhoneNumber('')
      setCity('')
      setCompany('')
      setInterestOn('')
      setMessage('')
      setDataForm({ name: '', email: '', phoneNumber: '', city: '', company: '', interestOn: '', message: '' })
      setEmailSent(true)
    } else {
      toast.error('Erro ao enviar dados! Por favor, revise os dados e tente enviar novamente.')
    }
  }

  useEffect(() => {
    if (
      !emailSent &&
      name !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      city !== '' &&
      company !== '' &&
      interestOn !== ''
    ) {
      sendEmail()
    }
  }, [dataForm])

  return (
    <>
      <Styles.BannerContent>
        <div className="overlay" />
        <Styles.VideoFrame autoPlay muted loop>
          <Styles.SourceFrame src={bannerPartnesVideo} type="video/mp4" />
        </Styles.VideoFrame>
        <div className="content">
          <h1 className="title">Cannect Parceiros</h1>
          <p className="subTitle">Transformando, juntos!</p>
        </div>
      </Styles.BannerContent>
      <Styles.Wrapper>
        <Styles.Section>
          <Styles.Box>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Quem somos
            </Heading>
            <TextDanger text={AboutUs} width="580px" />

            <Styles.ActionsContent>
              <Button background="green" onClick={() => redirectForm('product')}>
                Quero apresentar meus produtos
              </Button>
              <Button background="green" onClick={() => setOpenEmailModal(true)}>
                Quero falar de educação
              </Button>
              <Button
                background="green"
                onClick={() => redirectLink(`https://atendimento.cannect.life/agentes-autonomos${UTMs['organic_site_cannect_botao']}`)}>
                Quero ser um Consultor Canábico parceiro da Cannect
              </Button>
            </Styles.ActionsContent>
          </Styles.Box>
          <Styles.Box justify="end">
            <Styles.Image src={ecosystem} />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section className="withCard">
          <Styles.Box fullWidth>
            <Heading elementType="h2" fontSize="40px">
              Pilares diferenciais
            </Heading>

            <div className="card-box">
              <div className="content-box">
                {DiferencePillars.map((item) => (
                  <Card title={item.title} text={item.text} icon={item.icon} key={item.title} />
                ))}
              </div>
            </div>
            <div className="action">
              <Button onClick={() => redirectLink(`https://atendimento.cannect.life/parceiros${UTMs['organic_site_cannect_botao']}`)}>
                {' '}
                QUERO SABER MAIS!{' '}
              </Button>
            </div>
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box>
            <Heading elementType="h2" fontSize="40px">
              Parceiro Cannect tem benefícios exclusivos
            </Heading>

            <TextDanger text={Services} width="600px" />
            <Button background="green" onClick={() => redirectLink('https://atendimento.cannect.life/parceiros')}>
              Quero ser Parceiro Cannect
            </Button>
          </Styles.Box>
          <Styles.Box justify="center">
            <AccordionPartnes data={AccordionDataPartnes} />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box>
            <Heading elementType="h2" fontSize="32px" fontWeight={400} defaultColor="darkGrey">
              <strong>Dr. Cannabis</strong>
              <br />
              Um novo paradigma em educação
            </Heading>

            <TextDanger text={dr_cannabis} width="660px" fontWeight={400} />
            <Button onClick={() => redirectLink('https://www.drcannabis.com.br/home')}> SAIBA MAIS </Button>
          </Styles.Box>
          <Styles.Box justify="end">
            <Styles.CardImage image={secondCardImage} role="img" aria-label="office cannect">
              <img src={drCannabisLogo} className="complement-logo" alt="" />
            </Styles.CardImage>
          </Styles.Box>
        </Styles.Section>

        <Styles.Section invertRow>
          <Styles.Box>
            <Heading elementType="h2" fontSize="32px" fontWeight={400} defaultColor="darkGrey">
              <strong>Representante Cannect</strong>
              <br />
              Uma nova dimensão no mundo da cannabis medicinal
            </Heading>

            <TextDanger text={education} width="660px" />
            <Button onClick={redirectLinkCustom}>SAIBA MAIS</Button>
          </Styles.Box>
          <Styles.Box justify="start">
            <Styles.CardImage image={firstCardImage} role="img" aria-label="office cannect with logo" />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box fullWidth>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Parceiros Cannect
            </Heading>

            <Styles.PartnesLogoContainer>
              {partnesLogos.map((item) => (
                <Styles.PartnesLogo
                  src={item.image}
                  alt={item.alt}
                  key={item.image}
                  onClick={() => history.push(item.path)}
                  width={180}
                  height={60}
                />
              ))}
            </Styles.PartnesLogoContainer>
            <div className="action-link">
              <Styles.LinkText onClick={() => history.push('/marcas')}>Veja todos os parceiros</Styles.LinkText>
            </div>
          </Styles.Box>
        </Styles.Section>
        {/*  <Styles.SectionForm background="#21976E" lastSection fullWidth id="sectionForm">
          <FormPartnes
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            city={city}
            setCity={setCity}
            company={company}
            setCompany={setCompany}
            interestOn={interestOn}
            setInterestOn={setInterestOn}
            message={message}
            setMessage={setMessage}
            setDataForm={setDataForm}
          />
        </Styles.SectionForm> */}

        <Modal isOpen={openEmailModal} setOpenModal={setOpenEmailModal}>
          <Styles.ModalEmailBox>
            <Styles.ModalEmailContent>
              Para saber mais entre contato conosco pelo e-mail
              <Styles.LinkEmail href="mailto:cursos@drcannabis.com.br"> cursos@drcannabis.com.br</Styles.LinkEmail>
            </Styles.ModalEmailContent>
          </Styles.ModalEmailBox>
        </Modal>
      </Styles.Wrapper>
    </>
  )
}
