import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Button, Typography } from '@cannect/new-components/atoms'
import InvisibleButton from 'components/InvisibleButton'
import { useAuth } from 'hooks/AuthContext'
import useMediaQuery from 'hooks/useMediaQuery'
import { getPathFrom, removePahtFrom, setPathFrom } from 'hooks/usePathsRedirect'
import { useTokenApi } from 'hooks/useTokenApi'
import CheckboxInput from 'new-components/CheckboxInput'
import Input from 'new-components/Input'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import { authenticate } from 'usecases/user'
import { MOBILE_WIDTH } from 'utils/constants'
import Loading from '../../components/Loading'
import { setUser } from '../../hooks/useSessionUser'
import { defaultsHeadersAxios } from '../../services/api'
import * as Styles from './styles'

function Login() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { push } = useHistory()
  const { state } = useLocation() as any
  const { getRoles } = useAuth()
  const { setTokenApi } = useTokenApi()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remind, setRemind] = useState(false)
  const [renderCreateAccount, setRenderCreateAccount] = useState(false)

  const utmRegister = `?utm_medium=organic&utm_source=site&utm_campaign=202[…]site_cannect_organic_geral&utm_content=cannect&utm_term=link`

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault()
    try {
      setLoading(true)
      const response = await authenticate({ email, password })
      setTokenApi(response.token)
      defaultsHeadersAxios(response.token)
      await setUser(response.user)
      getRoles()

      const pathFrom = await getPathFrom()
      const pageToRedirect = pathFrom || '/painel-do-usuario'

      push(pageToRedirect)
      await removePahtFrom()
    } catch (e: any) {
      toast.error('E-mail ou senha incorretos')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (state && state?.from && state?.from?.pathname) {
      setPathFrom(state?.from?.search ? `${state?.from?.pathname}${state?.from?.search}` : state?.from?.pathname)
    }
  }, [])

  return (
    <Styles.LoginContainer>
      <Loading loading={loading} />
      {(!isMobile || !renderCreateAccount) && (
        <Styles.ContentWrapper usage="login">
          {isMobile && (
            <span>
              Primeiro acesso?
              <Styles.FirstAccessButton onClick={() => push('/cadastro')}>Clique aqui.</Styles.FirstAccessButton>
            </span>
          )}
          <Typography.Heading type="headingOne" weight="semibold" className="text-primary-700 lg:text-[32px]">
            Faça seu login
          </Typography.Heading>

          <Styles.LoginForm onSubmit={handleLogin}>
            <Input value={email} onChange={(event) => setEmail(event.target.value)} placeholder="E-mail ou CPF*" />
            <Input
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Senha*"
              type="password"
            />
            <Styles.ForgotPasswordContainer>
              <InvisibleButton
                className="pt-2"
                type="button"
                color="#7D755F"
                style={{ textDecoration: 'none' }}
                onClick={() => push('/esqueci-minha-senha')}>
                Esqueci minha senha
              </InvisibleButton>
              <CheckboxInput
                checked={remind}
                onChange={() => setRemind((state) => !state)}
                label="Lembrar de mim"
                id="remember-me"
                color="#464F31"
              />
            </Styles.ForgotPasswordContainer>
            <ReCAPTCHA
              sitekey="6LfI-rcjAAAAAGtDe1_H4sxhfEXy-gjGIXWY_VL_"
              size="invisible"
              badge="bottomleft"
              onChange={() => console.log('sucesso!')}
            />
            <Button type="submit" className="h-[50px]">
              Entrar na Cannect
            </Button>
          </Styles.LoginForm>
        </Styles.ContentWrapper>
      )}

      {(!isMobile || renderCreateAccount) && (
        <Styles.ContentWrapper usage="createAccount" className="pb-[10px]">
          {isMobile && (
            <span>
              Já possui uma conta?
              <Styles.FirstAccessButton onClick={() => setRenderCreateAccount((state) => !state)}>
                Faça login aqui.
              </Styles.FirstAccessButton>
            </span>
          )}
          <Typography.Heading type="headingOne" weight="semibold" className="mt-[10px] text-primary-700 lg:text-[32px]">
            Ainda não possui uma conta?
          </Typography.Heading>

          <Typography.Text type="paragraphOne" className="text-neutral-800">
            Aproveite e crie a sua agora =)
          </Typography.Text>

          <Button type="button" onClick={() => push(`/cadastro${utmRegister}`)} className="mb-[60px] mt-[50px] h-[50px] w-[317px]">
            Quero me cadastrar
          </Button>
        </Styles.ContentWrapper>
      )}
    </Styles.LoginContainer>
  )
}

export default Login
