import styled from 'styled-components'

interface ButtonForm {
  active?: boolean
  isMobile?: boolean
}

interface MobileProps {
  isMobile?: boolean
}

export const Container = styled.div<MobileProps>`
  ${(props) =>
    props.isMobile
      ? `
    height: 450px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  `
      : `
    height: 450px;
    width: 800px;
  `}
`

export const ButtonHeaders = styled.div<MobileProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  ${(props) => (props.isMobile ? `margin-top: 30px;` : `padding: 0 20px;`)}
`

export const ButtonLogin = styled.button<ButtonForm>`
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;

  ${(props) =>
    props.isMobile
      ? `
    width: 100%;
  height: 50px;
  `
      : `
     width: 280px;
  height: 50px;
  `}

  ${(props) =>
    props.active
      ? `
    background-color: rgb(116, 131, 81);
    color: white;
    border: none;
  `
      : `
    background-color: transparent;
    color: rgb(116, 131, 81);
    border: 1px solid rgb(116, 131, 81);
  `}
`

export const ButtonRegister = styled.button<ButtonForm>`
  width: 280px;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;

  ${(props) =>
    props.active
      ? `
    background-color: rgb(116, 131, 81);
    color: white;
    border: none;
  `
      : `
    background-color: transparent;
    color: rgb(116, 131, 81);
    border: 1px solid rgb(116, 131, 81);
  `}
`
export const ContainerForms = styled.div<MobileProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  ${(props) =>
    props.isMobile &&
    `
    height: 100%;
    // align-items: center;
  `}
`

export const FormLogin = styled.form<MobileProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${(props) =>
    props.isMobile
      ? `
    height: 100%;
    justify-content: center;
    width: 100%;
  `
      : `
    margin-top: 20px;
    width: 70%;
  `}
`

export const Input = styled.input<any>`
  width: ${(props) => props.width || '100%'};
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`

export const ButtonFormLogin = styled.button`
  width: 100%;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid rgb(116, 131, 81);
  border-radius: 5px;
  background-color: transparent;
  color: rgb(116, 131, 81);

  &:hover {
    background-color: rgb(116, 131, 81);
    color: white;
  }
`
export const InputGroup = styled.div<MobileProps>`
  display: flex;
  gap: 20px;
  flex-direction: row;
  ${(props) => props.isMobile && `width: 100%;`}
`

export const TogglePassword = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`

export const ErrorMessage = styled.span`
  color: #ff4d4f;
  font-size: 1rem;
  margin-top: 4px;
  display: block;
`
