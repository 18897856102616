import { filterIcon, filterMobile } from 'assets/svg'
import { useMarketPlace } from 'hooks/useMarketPlace'
import useSignedUser from 'hooks/useSignedUser'
import { useTokenApi } from 'hooks/useTokenApi'
import Button from 'new-components/Button'
import AnimatedInput from 'new-components/partnesComponents/AnimatedInput'
import { VisualizationMode } from 'pages/Products/mockFilterProduct'
import { BiSearchAlt2 } from 'react-icons/bi'
import When from 'utils/when'
import FilterDropdown from '../FilterDropdown'
import * as Styles from './styles'

export interface ProductFilterProps {
  isAuth?: boolean
  search?: string
  setSearch?: any
  count?: number
  openModalFilter?: any
  countValue?: number
  isContentBlocked?: boolean
}

export default function ProductFilter({
  search,
  setSearch = () => null,
  count = 1,
  openModalFilter,
  countValue,
  isContentBlocked
}: ProductFilterProps) {
  const { selectedValue, setSelectedValue } = useMarketPlace()
  const { signedUser } = useSignedUser()
  const { tokenApi } = useTokenApi()

  return (
    <Styles.Wrapper>
      <Styles.FilterContainer>
        <Styles.SearchBox>
          <AnimatedInput
            value={(!!search && search) || undefined}
            label="Buscar produtos"
            bgColor="#ffffff"
            color="dark"
            icon={<BiSearchAlt2 />}
            width="100%"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </Styles.SearchBox>

        <Styles.SelectActionBox>
          <Styles.FilterModalBox>
            <Button onClick={openModalFilter} background="black">
              <Styles.FilterModalIcon src={filterMobile} /> Filtros ({countValue})
            </Button>
          </Styles.FilterModalBox>
          {!!tokenApi && (
            <When expr={signedUser.registration_type === 2}>
              <FilterDropdown
                title="Visualização"
                imageIcon={filterIcon}
                data={VisualizationMode}
                selectedValue={selectedValue}
                onChangeValue={setSelectedValue}
                hiddenMobile
              />
            </When>
          )}
        </Styles.SelectActionBox>
      </Styles.FilterContainer>

      <Styles.DetailsContainer>
        {!isContentBlocked && (
          <Styles.NotSearchText>
            Não encontrou seu produto?
            <Styles.LinkCustom
              href="https://atendimento.cannect.life/atendimento-cannect"
              target="_blank"
              rel="noreferrer">
              <span> Envie aqui sua receita e documentos.</span>
            </Styles.LinkCustom>
          </Styles.NotSearchText>
        )}

        <Styles.ResultsText>
          {count > 12 ? '12' : count} de {count} resultados
        </Styles.ResultsText>
      </Styles.DetailsContainer>
    </Styles.Wrapper>
  )
}
