import { bannerHealthProfessional, cannectOffice, tratamentoComCannabisMedicinalCannect1 } from 'assets/img'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import BannerHealth from 'new-components/HealthComponents/BannerHealth'
import AccordionPartnes from 'new-components/partnesComponents/AccordionPartnes'
import Card from 'new-components/partnesComponents/Card'
import TextDanger from 'new-components/partnesComponents/TextDanger'

import { Post } from 'components/Home/CannabicastSection'
import CardPodcastPlayer from 'new-components/CardPodcastPlayer'
import { useEffect, useState } from 'react'
import { HiRss } from 'react-icons/hi'
import { RiSpotifyLine } from 'react-icons/ri'
import { SiGooglepodcasts } from 'react-icons/si'
import ReactPlayer from 'react-player'
import { toast } from 'react-toastify'
import api from 'services/api'
import { AboutUs, AccordionHealth, consulting, DiferencePillars, education, Services } from './HealthMock'
import * as Styles from './styles'

export default function HealthProfessionals() {
  const [podcasts, setPodcasts] = useState<Post[]>([])

  const redirectLink = (link = 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }

  const getPodcastList = async () => {
    try {
      const {
        data: { success, podcasts }
      } = await api.get('/podcasts/1?limit=2')
      if (success) {
        setPodcasts(podcasts?.rows)
      }
    } catch (e) {
      toast.error('Erro ao carregar podcast atualizado')
    }
  }

  useEffect(() => {
    getPodcastList()
  }, [])

  return (
    <>
      <BannerHealth backgroundImage={bannerHealthProfessional} />
      <Styles.Wrapper>
        <Styles.Section>
          <Styles.Box>
            <Heading as="h2" fontSize="40px" defaultColor="darkGrey">
              Quem somos
            </Heading>
            <TextDanger text={AboutUs} width="525px" />
          </Styles.Box>
          <Styles.Box justify="end">
            <ReactPlayer url="https://www.youtube.com/watch?v=7FVuGd5FrNs" width="100%" controls />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Pacotes de soluções
            </Heading>

            <TextDanger text={Services} width="600px" />
            <Button
              background="green"
              onClick={() =>
                redirectLink('https://api.whatsapp.com/send/?phone=5511950811547&text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+a+Cannect+%21+-+Inicie+a+conversa+para+ser+atendido&type=phone_number&app_absent=0')
              }>
              Quero saber mais
            </Button>
          </Styles.Box>
          <Styles.Box justify="center">
            <AccordionPartnes data={AccordionHealth} />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box fullWidth>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Pilares diferenciais
            </Heading>

            <div className="card-box">
              <div className="content-box">
                {DiferencePillars.map((item) => (
                  <Card title={item.title} text={item.text} icon={item.icon} key={item.title} />
                ))}
              </div>
            </div>
            <div className="action">
              <Button
                background="black"
                onClick={() =>
                  redirectLink('https://api.whatsapp.com/send/?phone=5511950811547&text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+a+Cannect+%21+-+Inicie+a+conversa+para+ser+atendido&type=phone_number&app_absent=0')
                }>
                QUERO SABER MAIS!
              </Button>
            </div>
          </Styles.Box>
        </Styles.Section>

        <Styles.Section invertRow>
          <Styles.Box>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Vertical de educação
            </Heading>
            <TextDanger text={education} width="660px" />

            <Button
              background="black"
              onClick={() =>
                redirectLink('https://www.drcannabis.com.br/?utm_source=pagina-pds&utm_medium=cta&utm_id=cannect')
              }>
              SAIBA MAIS
            </Button>
          </Styles.Box>
          <Styles.Box justify="start">
            <Styles.CardImage
              image={tratamentoComCannabisMedicinalCannect1}
              role="img"
              aria-label="office cannect with logo"
            />
          </Styles.Box>
        </Styles.Section>

        <Styles.Section>
          <Styles.Box>
            <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey">
              Ainda tem dúvidas?
            </Heading>

            <TextDanger text={consulting} width="660px" fontWeight={400} />
            <Button
              background="green"
              onClick={() => redirectLink('https://api.whatsapp.com/send/?phone=5511950811547&text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+a+Cannect+%21+-+Inicie+a+conversa+para+ser+atendido&type=phone_number&app_absent=0')}>
              Agende seu bate papo de Boas Vindas
            </Button>
          </Styles.Box>
          <Styles.Box justify="end">
            <Styles.CardImage image={cannectOffice} role="img" aria-label="office cannect" />
          </Styles.Box>
        </Styles.Section>

        <Styles.SectionCannabiCast>
          <Styles.TextContent>
            <div className="title-box">
              <Heading elementType="h2" fontSize="40px" defaultColor="darkGrey" verticalSpace="small">
                Cannabicast
              </Heading>
              <Heading
                elementType="p"
                fontSize="20px"
                defaultColor="darkGrey"
                fontWeight={500}
                verticalSpace="small"
                className="text">
                Conteúdos novos toda semana
              </Heading>
            </div>

            <Styles.SocialLinkContent>
              <Heading elementType="p" fontSize="20px" defaultColor="darkGrey" fontWeight={400}>
                Escute também pelas plataformas:
              </Heading>
              <Styles.ListLink>
                <RiSpotifyLine onClick={() => redirectLink('https://open.spotify.com/show/2L9VOkpEwZ0iGAQExjhMXr')} />
                <SiGooglepodcasts
                  onClick={() =>
                    redirectLink(
                      'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83Y2NjZGFhNC9wb2RjYXN0L3Jzcw'
                    )
                  }
                />
                <HiRss onClick={() => redirectLink('https://anchor.fm/s/7cccdaa4/podcast/rss')} />
              </Styles.ListLink>
            </Styles.SocialLinkContent>
          </Styles.TextContent>

          <Styles.PodcastContent>
            {podcasts.map((item) => (
              <CardPodcastPlayer key={item?.title} title={item?.title} image={item?.img} audioLink={item?.enclosure} />
            ))}
          </Styles.PodcastContent>
        </Styles.SectionCannabiCast>
      </Styles.Wrapper>
    </>
  )
}
