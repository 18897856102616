/* eslint-disable @typescript-eslint/no-use-before-define */
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'

import {
  TJourneyPaymentResponse,
  useConfirmJourneyPaymentStepMutation,
  useGetJourneyPaymentStatusQuery,
  useGetPublicInstallmentsByOrderId,
  useJourneyPaymentMutation
} from '@cannect/services/resources/digitalJourney'
import { formatToReal } from '@cannect/utils/number'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { FORM_DEFAULT_VALUES, FORM_SCHEMAS } from '../../constants'
import { TDigitalJourneyFormData } from '../../types'

type TFormFields = TDigitalJourneyFormData['order_payment']

export const useOrderPaymentStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()
  const [paymentInfo, setPaymentInfo] = useState<TJourneyPaymentResponse['paymentInfos'] | null>(null)
  const paymentMethodContentRef = useRef<HTMLDivElement>(null)
  const [showWaitingPaymentScreen, setShowWaitingPaymentScreen] = useState(false)

  const orderAmount = useMemo(() => formData?.cart?.summary?.amount_liquid, [formData?.cart?.summary])
  const orderId = formData?.common_fields?.order_id
  const digitalJourneyId = formData?.common_fields?.digital_journey_id

  const journeyPaymentMutation = useJourneyPaymentMutation()
  const confirmJourneyPaymentStepMutation = useConfirmJourneyPaymentStepMutation()

  const { data: paymentStatusData, refetch: refetchPaymentStatus } = useGetJourneyPaymentStatusQuery(
    orderId,
    paymentInfo
  )

  const paymentStatus = useMemo(() => paymentStatusData?.payment?.status, [paymentStatusData])

  const { data: installmentsList, isLoading: isLoadingInstallments } = useGetPublicInstallmentsByOrderId(
    orderId,
    orderAmount
  )

  const paymentForm = useForm<TFormFields>({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES.order_payment,
    resolver: zodResolver(FORM_SCHEMAS.order_payment)
  })

  const { watch } = paymentForm

  const selectedMethod = watch('payment_method')

  const installmentsOptions = useMemo(
    () =>
      installmentsList?.installments.map((installment) => ({
        value: installment.id,
        label: `${installment.installment} de ${formatToReal(installment.amount)}`,
        endAdornment: (
          <span className="min-w-fit text-xs !text-primary-700">
            {installment.fees === '(sem juros)' ? 'sem juros' : `${formatToReal(installment.total)}`}
          </span>
        )
      })),
    [installmentsList]
  )

  const processPayment = async (data: TFormFields) => {
    if (!orderId || !selectedMethod) return null

    const paymentData = {
      order_id: orderId,
      paymentMethod: { type: selectedMethod },
      shippingAddress: extractShippingAddress(formData),
      creditCard: selectedMethod === 'credit_card' ? extractCreditCardInfo(data) : undefined
    }

    return journeyPaymentMutation.mutateAsync(paymentData, {
      onSuccess: (response) => {
        updateFormData({
          common_fields: {
            ...formData.common_fields,
            order_uuid: response.order.uuid
          }
        })
        setPaymentInfo(response.paymentInfos)
        refetchPaymentStatus()
      }
    })
  }

  const handlePaidStatusClick = async () => {
    if (!digitalJourneyId) return
    const response = await confirmJourneyPaymentStepMutation.mutateAsync({ digital_journey_id: digitalJourneyId })
    if (response.next_step) {
      history.push(response.next_step)
      updateFormData({
        common_fields: {
          ...formData.common_fields,
          status: 'paid'
        }
      })
    }
  }

  const handlePaymentStatusClick = {
    paid: handlePaidStatusClick,
    refused: () => setPaymentInfo(null),
    awaiting_payment: () => history.push(`/detalhes-do-pedido/${orderId}`)
  }

  const handlePixPayment = () => processPayment(paymentForm.getValues())

  useEffect(() => {
    if (selectedMethod === 'pix') {
      handlePixPayment()
    }
  }, [selectedMethod])

  const onSubmit = (data: TFormFields) => (selectedMethod !== 'pix' ? processPayment(data) : null)

  const showPaymentStatusScreen = useMemo(() => {
    return (
      paymentInfo &&
      paymentStatus &&
      (paymentStatus === 'paid' ||
        (paymentStatus === 'refused' && selectedMethod === 'credit_card') ||
        showWaitingPaymentScreen)
    )
  }, [paymentInfo, paymentStatus, selectedMethod, showWaitingPaymentScreen])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (selectedMethod === 'boleto' && paymentStatus === 'awaiting_payment' && paymentInfo) {
      // send to waiting payment status screen after 5 seconds when boleto is generated.
      timer = setTimeout(() => setShowWaitingPaymentScreen(true), 10000)
    } else {
      setShowWaitingPaymentScreen(false)
    }
    return () => clearTimeout(timer)
  }, [selectedMethod, paymentStatus, paymentInfo])

  useEffect(() => {
    setPaymentInfo(null)
  }, [selectedMethod])

  useEffect(() => {
    if (selectedMethod && paymentMethodContentRef?.current) {
      paymentMethodContentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedMethod, paymentMethodContentRef?.current])

  return {
    onSubmit,
    paymentForm,
    selectedMethod,
    isLoadingInstallments,
    journeyPaymentMutation,
    installmentsOptions,
    handlePaymentStatusClick,
    handlePixPayment,
    paymentInfo,
    isLoading:
      journeyPaymentMutation.isPending || (selectedMethod === 'credit_card' && paymentStatus === 'awaiting_payment'),
    orderAmount,
    showPaymentStatusScreen,
    paymentStatus,
    paymentMethodContentRef
  }
}

// helpers
const extractShippingAddress = (formData: TDigitalJourneyFormData) => ({
  address_number: formData?.confirm_proof_of_address_document?.address_number,
  cep: formData?.confirm_proof_of_address_document?.address_cep,
  state: formData?.confirm_proof_of_address_document?.address_state,
  street: formData?.confirm_proof_of_address_document?.address_street,
  district: formData?.confirm_proof_of_address_document?.address_neighborhood,
  city: formData?.confirm_proof_of_address_document?.address_city
})

const extractCreditCardInfo = (data: TFormFields) => ({
  card_month_validate: data.credit_card.card_validate.split('/')[0],
  card_year_validate: data.credit_card.card_validate.split('/')[1],
  card_cvv: data.credit_card.card_cvv,
  card_name: data.credit_card.card_holder_name,
  card_number: data.credit_card.card_number,
  installments: data.credit_card.installments.value ?? data.credit_card.installments
})
