/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space, Typography } from 'antd'
import EmptyState from 'components/EmptyState'
import { InputSearch } from 'components/InputSearch'
import LoadingContainer from 'components/LoadingContainer'
import Pagination from 'components/Pagination'
import NewFilter from 'components/ProductsComponent/NewFilters'
import { useFilterProduct } from 'hooks/FilterProductContext'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import useMediaQuery from 'hooks/useMediaQuery'
import useQueryString from 'hooks/useQueryString'
import { debounce } from 'lodash'
import Button from 'new-components/Button'
import FilterBadge from 'new-components/MarketPlace/FilterBadge'
import Modal from 'new-components/Modal'
import { ModalFilterContent } from 'pages/Products/styles'
import { ArrowsDownUp, SlidersHorizontal } from 'phosphor-react'
import queryString from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import { breakpointsToHook } from 'utils/breakpoints'
import { MOBILE_WIDTH } from 'utils/constants'
import { transformFilterProductsUrl } from 'utils/formatedParamsUrl'
import When from 'utils/when'
import ListProducts from './ListProducts'
import { ORDER_MENU_FILTER } from './Mock/FiltersMock'
import * as Style from './styles'

const limitPage = 20
function NewProductSelection() {
  const { getFilters, keyFilter } = useFilterProduct()
  const { filters } = useQueryString()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [countPage, setCountPage] = useState(1)
  const [sortProduct, setSortProduct] = useState('Nenhum')
  const [products, setProducts] = useState<any>([])
  const [loadingContainer, setLoading] = useState(true)
  const [filterModal, setFilterModal] = useState(false)
  const [reset, setReset] = useState(false)
  const [tableFormat, setTableFormat] = useState<'list' | 'table' | 'list-mobile'>('table')
  const location = useLocation()
  const history = useHistory()
  const [search, setSearch] = useState('')
  const { pagesProducts } = useParams() as any
  const keyParamsSearchUrls = transformFilterProductsUrl(location.search) as any
  const [counterFilter, setCounterFilter] = useState(0)
  const keyParamsSearchUrl = queryString.stringify(transformFilterProductsUrl(location.search))
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const isDesktop = useMediaQuery(breakpointsToHook.desktop)
  const { setStep, prescription } = useCannectAssistant()

  const [selectedOrderKey, setselectedOrderKey] = useState(['3'])
  const [sortDetails, setSortDetails] = useState<{ sortBy?: string; sortDir?: string; sortName?: string }>({})

  const isPrescriberLogo = prescription.prescriber.profilePicture

  const updateQuery = () => {
    const keyParamsSearchUrls = transformFilterProductsUrl(location.search)
    keyParamsSearchUrls.translated_name = search
    const newSearch = queryString.stringify(keyParamsSearchUrls)
    history.push({ pathname: '/produtos_assistente/1', search: newSearch })
  }

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search])

  useEffect(() => {
    delayedQuery()
    return delayedQuery.cancel
  }, [search])

  useEffect(() => {
    getFilters()
  }, [])

  const getProducts = async (params?: any) => {
    try {
      setLoading(true)
      const { data } = await api.get(
        `products_assistant/${pagesProducts || 1}?limit=${limitPage}&${keyParamsSearchUrl}`,
        {
          params
        }
      )
      setCountPage(Math.ceil(data.products.count / limitPage))
      setCount(data.products.count)
      const dados = data.products.rows.map((product: any) => {
        const { media, id, translated_name, price, currency, cannect_choice, promotion, ...rest } = product
        return { id, image: media[0]?.path, translated_name, price, currency, cannect_choice, promotion, ...rest }
      })
      setProducts(dados)
    } catch (error) {
      history.push('/login')
    } finally {
      setLoading(false)
    }
  }

  const handlePage = (selected: number) => {
    setPage(selected)
    history.push({ pathname: `/produtos_assistente/${selected}`, search: keyParamsSearchUrl })
  }

  const countFilters = (params: Record<string, string | string[] | any>): number => {
    let totalFilters = 0

    keyFilter?.forEach((key: any) => {
      if (params[key]) {
        totalFilters += Array.isArray(params[key]) ? params[key].length : 1
      }
    })

    return totalFilters
  }
  useEffect(() => {
    if (pagesProducts) {
      setPage(pagesProducts)
    }
  }, [pagesProducts])

  useEffect(() => {
    const searchParams = location.search
    const queryStringParams = searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
    const params = queryString.parse(queryStringParams)
    const totalFilters = countFilters(params)
    setCounterFilter(totalFilters)
  }, [location.search])

  useEffect(() => {
    getProducts({ sortBy: sortDetails.sortBy, sortDir: sortDetails.sortDir })
  }, [location.search, pagesProducts, sortDetails])

  const isAnyProductIncluded = prescription.products.length > 0

  const continueToAssistant = () => {
    const validateStep = isPrescriberLogo ? 2 : 1
    setStep((step) => step + validateStep)
    let url = '/assistente'
    if (filters.template) {
      url += `?template=${filters.template}`
    }
    history.push(url)
  }

  const cancelAssistant = () => {
    setStep((step) => step - 1)
    history.push('/assistente')
  }

  useEffect(() => {
    if (isMobile) {
      setTableFormat('table')
    }
  }, [isMobile])

  const clearFilters = () => {
    setSearch('')
    history.push({ pathname: '/produtos_assistente/1', search: '' })
    setReset(true)
  }

  const handleFilterMobile = () => {
    setFilterModal(false)
    toast.success('Filtros aplicados com sucesso.')
  }

  const handleSortProduct = ({ sortBy, sortDir, sortName }: { sortBy: string; sortDir: string; sortName: string }) => {
    setSortProduct(sortName)
    setSortDetails({ sortBy, sortDir, sortName })
    const params = transformFilterProductsUrl(location.search)
    delete params.sortBy
    delete params.sortDir
    params.sortBy = sortBy
    params.sortDir = sortDir
  }

  const handleSelect = (order: any) => {
    const newSelectedKey = [order.key]
    setselectedOrderKey(newSelectedKey)
    handleSortProduct({
      sortBy: order.item?.props?.by,
      sortDir: order.item?.props?.order,
      sortName: order.item?.props?.name
    })
  }

  return (
    <Style.Container isMobile={isMobile}>
      <div className="wrapper">
        <div className="filter">
          <When expr={!!isDesktop}>
            <Style.CancelButton onClick={() => cancelAssistant()}>Voltar ao inicio</Style.CancelButton>
            <Style.FiltersBox className="outHeader column">
              <Style.FilterResult className="filters-result">
                <SlidersHorizontal size={22} /> Filtros <span>({counterFilter})</span>
              </Style.FilterResult>
              <Style.BadgeFilterBox>
                {keyFilter?.length > 0 &&
                  keyFilter?.map((item: any) =>
                    keyParamsSearchUrls[item] ? (
                      <FilterBadge
                        item={item}
                        key={item}
                        clearFilter={clearFilters}
                        pathFilter="/produtos_assistente/1"
                      />
                    ) : null
                  )}
              </Style.BadgeFilterBox>
            </Style.FiltersBox>

            <NewFilter reset={reset} setReset={setReset} pathFilter="/produtos_assistente/1" />
          </When>
        </div>
        <div className="products">
          <div className="header">
            <div className="product-header__search-box">
              {!isDesktop && (
                <Style.CancelButton onClick={() => cancelAssistant()}>Voltar ao inicio</Style.CancelButton>
              )}
              <InputSearch value={search} width={isDesktop ? '85%' : ''} onChange={(e) => setSearch(e.target.value)} />
              {!isDesktop && (
                <Style.FiltersBox>
                  <Style.FilterResult className="filters-result" onClick={() => setFilterModal(true)}>
                    <SlidersHorizontal size={22} /> Filtros <span>({counterFilter})</span>
                  </Style.FilterResult>

                  <p className="count-result">
                    {products.length} de {count} resultados
                  </p>
                </Style.FiltersBox>
              )}
            </div>
            <div className="product-header__button-box">
              <When expr={!!isDesktop}>
                <Style.ContainerButtons>
                  <Style.SaveButton disabled={!isAnyProductIncluded} onClick={() => continueToAssistant()}>
                    CONTINUAR
                  </Style.SaveButton>
                </Style.ContainerButtons>
              </When>
            </div>
          </div>
          <When expr={!!isDesktop}>
            <Style.FiltersBox className="outHeader">
              <Style.FilterSort>
                <Dropdown
                  arrow
                  overlayClassName="filter_order"
                  overlayStyle={{ fontFamily: 'Nunito' }}
                  menu={{
                    items: ORDER_MENU_FILTER,
                    selectable: true,
                    defaultSelectedKeys: selectedOrderKey,
                    onSelect: handleSelect
                  }}>
                  <Typography.Link>
                    <Space>
                      <ArrowsDownUp size={22} />
                      Ordenar
                      <DownOutlined />
                    </Space>
                  </Typography.Link>
                </Dropdown>
                <span> {sortProduct} </span>
              </Style.FilterSort>

              <p className="count-result">
                {products.length} de {count} resultados
              </p>
            </Style.FiltersBox>
          </When>
          {loadingContainer ? (
            <LoadingContainer loading />
          ) : (
            <Style.ContentProduct>
              {products.length > 0 ? (
                <Style.ProductList>
                  <ListProducts products={products} tableFormat={tableFormat} />
                  {countPage > 1 && (
                    <div className="page">
                      <Pagination
                        forcePage={page - 1}
                        onPageChange={({ selected }: any) => handlePage(selected + 1)}
                        pageCount={countPage}
                      />
                    </div>
                  )}
                </Style.ProductList>
              ) : (
                <EmptyState
                  title="Não localizamos produtos"
                  subTitle="Não localizamos nenhum produto(s) com o(s) filtro(s) selecionado(s)!"
                />
              )}
            </Style.ContentProduct>
          )}
        </div>
      </div>

      <When expr={!isDesktop}>
        <div className="actions-responsive">
          <Button background="black" onClick={() => cancelAssistant()}>
            Voltar para o inicio
          </Button>
          <Button background="green" disabled={!isAnyProductIncluded} onClick={() => continueToAssistant()}>
            Continuar
          </Button>
        </div>
      </When>
      <Modal title="Filtro de produtos" isOpen={filterModal} setOpenModal={setFilterModal} withRadius={false}>
        <ModalFilterContent>
          <NewFilter reset={reset} setReset={setReset} pathFilter="/produtos_assistente/1" />
          <Style.BadgeFilterBoxMobile>
            {keyFilter?.length > 0 &&
              keyFilter?.map((item: any) =>
                keyParamsSearchUrls[item] ? (
                  <FilterBadge item={item} key={item} clearFilter={clearFilters} pathFilter="/produtos_assistente/1" />
                ) : null
              )}
          </Style.BadgeFilterBoxMobile>
          <div className="clear-filter">
            <Button background="default" onClick={handleFilterMobile}>
              Aplicar filtros
            </Button>
            <Button background="black" isOutlined onClick={clearFilters}>
              limpar filtros
            </Button>
          </div>
        </ModalFilterContent>
      </Modal>
    </Style.Container>
  )
}

export default NewProductSelection
