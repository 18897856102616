import BreadCrumb from 'components/BreadCrumb'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { Path } from 'hooks/useCannectAssistant/types'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Modal from 'new-components/Modal'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router'
import api from 'services/api'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'
import AlreadyKnow from './components/AlreadyKnow'
import NeedHelp from './components/NeedHelp'
import { PatientList } from './components/PatientData/PatientList'
import TemplatesModal from './components/TemplatesModal'
import * as Style from './styles'

export default function CannectAssistant() {
  const history = useHistory()
  const {
    setStep,
    step,
    path,
    prescription,
    loading,
    setLoading,
    setPath,
    addProductToPrescription,
    getPatientList,
    fetchPrescriberData,
    getPatientByCpf
  } = useCannectAssistant()
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false)
  const [withTemplate, setWithTemplate] = useState(false)
  const [isNotTemplateModal, setIsNotTemplateModal] = useState(false)

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const {
          data: {
            templates: { count }
          }
        } = await api.get('/templates')
        setWithTemplate(count > 0)
      } catch (err: any) {
        if (err.response?.status === 401) {
          history.push('/login')
        }
        console.error(err)
        setWithTemplate(false)
      }
    }

    if (path === '' && step === 0) {
      fetchTemplates()
    }
  }, [step, path])

  useEffect(() => {
    const element = document.getElementById('main-app-container')
    element?.scrollTo(0, 0)
  }, [step])

  useEffect(() => {
    let isComponentMounted = true

    const resolveInitialData = async () => {
      if (!isComponentMounted) return

      setLoading(true)
      await fetchPrescriberData()

      const productToPrescribe = localStorage.getItem('@CANNECT_PRODUCT_TO_PRESCRIBE')
      if (productToPrescribe) {
        const parsedProductToPrescribe = JSON.parse(productToPrescribe)

        const isProductAlreadyAdded = !!prescription.products.find(
          (product) => product.id === parsedProductToPrescribe.id
        )
        if (!isProductAlreadyAdded) {
          console.log('!isProductAlreadyAdded')
          addProductToPrescription(parsedProductToPrescribe)
          setPath('alreadyKnow')
          setStep(2)
          localStorage.removeItem('@CANNECT_PRODUCT_TO_PRESCRIBE')
        }
      }
      setLoading(false)
    }

    resolveInitialData()

    return () => {
      isComponentMounted = false
    }
  }, [])

  const handlePathChoice = (newPath: Path) => {
    setPath(newPath)
    setStep((prevStep) => prevStep + 1)
  }

  const handleOpenModal = () => {
    if (!withTemplate) {
      setIsNotTemplateModal(true)
    } else {
      setIsTemplatesModalOpen(true)
      setIsNotTemplateModal(false)
    }
  }

  const handlePatientByCpf = (document: string) => {
    getPatientByCpf(document)
    handlePathChoice('alreadyKnow')
  }

  return (
    <Style.Container>
      <BreadCrumb
        paths={[
          { label: 'Prescritores', link: '/area-profissionais' },
          { label: 'Cannect Assistente', link: '/assistente' }
        ]}
      />

      <TemplatesModal isOpen={isTemplatesModalOpen} onRequestClose={() => setIsTemplatesModalOpen(false)} />

      {path === '' && (
        <Style.Main isMobile={isMobile}>
          <h2>Você sabe qual produto prescrever?</h2>
          <Style.WhatToPrescribeContainer>
            <Style.PrescriptionOptionsContainer>
              <div>
                <Button background="green" onClick={() => handlePathChoice('alreadyKnow')}>
                  Já sei qual produto prescrever
                </Button>
                <Button background="black" onClick={() => handlePathChoice('needHelp')}>
                  Quero ajuda para encontrar um produto
                </Button>
              </div>

              <div>
                <Button
                  isOutlined
                  background="black"
                  onClick={handleOpenModal}
                  style={{ border: '1px solid rgb(70, 79, 49)', color: 'rgb(70, 79, 49)' }}>
                  Utilizar modelo de prescrição
                </Button>
                <Button
                  isOutlined
                  background="black"
                  onClick={() => history.push('/nutraceuticos')}
                  style={{ border: '1px solid rgb(70, 79, 49)', color: 'rgb(70, 79, 49)' }}>
                  Protocolos com Suplementos
                </Button>
              </div>
            </Style.PrescriptionOptionsContainer>
            <PatientList onClick={(document) => handlePatientByCpf(document)} />
          </Style.WhatToPrescribeContainer>
        </Style.Main>
      )}
      {path === 'needHelp' && <NeedHelp />}
      {path === 'alreadyKnow' && <AlreadyKnow />}

      <Modal isOpen={isNotTemplateModal} setOpenModal={setIsNotTemplateModal}>
        <Style.ContentModalNotTemplate>
          <Heading elementType="h2" defaultColor="darkGrey" verticalSpace="small">
            Você ainda não tem nenhum modelo cadastrado.
          </Heading>

          <Heading elementType="p" fontSize="17px" verticalSpace="small">
            Assista ao vídeo e descubra como cadastrar seu primeiro modelo:
          </Heading>

          <ReactPlayer id="no-template-video" url="https://www.youtube.com/watch?v=OcEMUZ_4VJI" width="100%" controls />

          <div className="action-box">
            <Button background="green" onClick={() => handlePathChoice('alreadyKnow')}>
              Iniciar prescrição
            </Button>
          </div>
        </Style.ContentModalNotTemplate>
      </Modal>
    </Style.Container>
  )
}
