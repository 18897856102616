import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const Wrapper = styled.main`
  padding: 0 ${temporaryTheme.spacing.space4};
  padding-top: 41px;

  @media (max-width: 900px) {
    position: relative;
  }

  .modal-produtos {
    padding: 0;
  }

  .banner-mes-do-acesso {
    width: 100%;
    height: 100%;
  }
`

export const CategoriesBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 68px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;

    /* O Safari pode necessitar do prefixo "-webkit-" para algumas propriedades CSS */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
  }
`

export const HeadingCategoryBox = styled.div`
  max-width: 350px;
`

export const CategoryList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 70%;
  overflow: auto;

  @media (max-width: 900px) {
    width: 100%;
    padding: 12px 0;
  }
`

export const ContentProduct = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`

export const FilterContainer = styled.div`
  width: 30%;
  max-width: 287px;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space3};
`

export const ProductListContainer = styled.div`
  width: 70%;
  min-height: 80vh;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ProductFilterContainer = styled.div`
  margin-bottom: 33px;
`

export const ProductGrid = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 28px;
  min-height: 50vh;
  @media (max-width: 900px) {
    justify-content: center;
  }
`

export const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
`

export const PaginateBox = styled.div`
  padding: 38px 0;
`

export const BadgeFilterBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const BadgeFilterBoxMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const ModalFilterContent = styled.div`
  padding: ${temporaryTheme.spacing.space4};
  width: 100%;

  .title-modal {
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: ${temporaryTheme.colors.darkGrey};
  }

  .buttonContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .icon-close {
    color: ${temporaryTheme.colors.sitePrimary};
    width: 32px;
    height: 32px;
    margin-top: -3px;
  }

  .clear-filter {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${temporaryTheme.spacing.space3};

    @media (max-width: 900px) {
      flex-direction: column;
    }

    button {
      width: 100%;
      font-family: 'Nunito', sans-serif;
      font-size: 15px;
      min-height: unset;

      border-radius: 8px;
    }
  }
`

export const ProductList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${temporaryTheme.spacing.space3};
`
