/* eslint-disable react/jsx-no-useless-fragment */
import BannerFlores from '@cannect/components/ProductsComponent/BannerFlores'
import { BadgeTreatmentLine } from 'components/BadgeTreatmentLine'
import EmptyState from 'components/EmptyState'
import LoadingContainer from 'components/LoadingContainer'
import Pagination from 'components/Pagination'
import { FilterBadgeMock } from 'components/ProductsComponent/Mock/FiltersMock'
import ModalFilter from 'components/ProductsComponent/ModalFilter'
import NewFilter from 'components/ProductsComponent/NewFilters'
import { useFilterProduct } from 'hooks/FilterProductContext'
import { useCartCheckout } from 'hooks/useCartCheckout'
import { useMarketPlace } from 'hooks/useMarketPlace'
import useMediaQuery from 'hooks/useMediaQuery'
import { useTokenApi } from 'hooks/useTokenApi'
import { debounce } from 'lodash'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import FilterBadge from 'new-components/MarketPlace/FilterBadge'
import ProductCard from 'new-components/MarketPlace/ProductCard'
import ProductFilter from 'new-components/MarketPlace/ProductFilter'
import queryString from 'querystring'
import { useCallback, useEffect, useState } from 'react'
import { IoCloseCircle } from 'react-icons/io5'
import { useHistory, useLocation, useParams } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import { transformFilterProductsUrl } from 'utils/formatedParamsUrl'
import When from 'utils/when'
import ProductGridList from '../../new-components/MarketPlace/ProductGridList/index'
import * as Styles from './styles'

const FLOWER_ANNOUNCEMENT = 'flower-accept'

function Products() {
  const { tokenApi } = useTokenApi()
  const { pagesProducts } = useParams() as any
  const [reset, setReset] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [products, setProducts] = useState<any>([])
  const location = useLocation()
  const [isBannerFlores, setIsBannerFlores] = useState(true)
  const [counterFilter, setCounterFilter] = useState(0)

  const [loading, setLoading] = useState(false)
  const keyParamsSearchUrl = queryString.stringify(transformFilterProductsUrl(location.search))
  const [search, setSearch] = useState('')
  const [selectCategory, setSelectCategory] = useState<any[]>([])
  const keyParamsSearchUrls = transformFilterProductsUrl(location.search) as any
  const limitPage = 12
  const { getFilters, keyFilter } = useFilterProduct()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [filterModal, setFilterModal] = useState(false)
  const { addToCart } = useCartCheckout()
  const { selectedValue, setSelectedValue } = useMarketPlace()

  const updateQuery = () => {
    const keyParamsSearchUrls = transformFilterProductsUrl(location.search)
    keyParamsSearchUrls.translated_name = search
    const newSearch = queryString.stringify(keyParamsSearchUrls)
    history.push({ pathname: '/produtos/1', search: newSearch })
  }

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search])
  useEffect(() => {
    delayedQuery()
    return delayedQuery.cancel
  }, [search])

  const getProductsAutenticated = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`products/${pagesProducts}?limit=${limitPage}&${keyParamsSearchUrl}`)
      setTotalCount(data?.products?.count)
      setCount(Math.ceil(data.products.count / limitPage))
      const dados = data.products.rows.map((product: any) => {
        const { media, id, translated_name, price, currency, cannect_choice, ...rest } = product
        const imagePath = product?.media?.filter((value: any) => value.format === 'IMG')
        return { id, image: imagePath[0]?.path, translated_name, price, currency, cannect_choice, ...rest }
      })
      setProducts(dados)
    } catch (error) {
      toast.error('Erro ao buscar produtos')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isMobile || !tokenApi) {
      setSelectedValue({ title: 'Em Grid', type: 'grid' })
    }
  }, [isMobile])

  const getPublicProducts = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`products_public/${pagesProducts}?limit=${limitPage}&${keyParamsSearchUrl}`)
      setTotalCount(data?.products?.count)
      setCount(Math.ceil(data.products.count / limitPage))
      const dados = data.products.rows.map((product: any) => {
        const { media, id, translated_name, price, currency, cannect_choice, ...rest } = product
        return { id, image: media[0]?.path, translated_name, price, currency, cannect_choice, ...rest }
      })
      setProducts(dados)
    } catch (error) {
      toast.error('Erro ao buscar produtos')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    setPage(pagesProducts)
  }, [pagesProducts])

  useEffect(() => {
    if (tokenApi) {
      getProductsAutenticated()
    } else {
      getPublicProducts()
    }

    if (keyParamsSearchUrls.shape_id) {
      if (keyParamsSearchUrls.shape_id === '29') {
        setIsBannerFlores(true)

        const flowersAnnouncement = localStorage.getItem(FLOWER_ANNOUNCEMENT)
      } else if (typeof keyParamsSearchUrls?.shape_id === 'object') {
        setIsBannerFlores(keyParamsSearchUrls?.shape_id.find((item: any) => item === '29'))
      }
    } else {
      setIsBannerFlores(false)
    }
    if (typeof keyParamsSearchUrls?.category_id === 'string') {
      setSelectCategory([keyParamsSearchUrls?.category_id])
    }
    if (typeof keyParamsSearchUrls?.category_id === 'object') {
      setSelectCategory(keyParamsSearchUrls?.category_id)
    }

    if (!keyParamsSearchUrls?.category_id) {
      setSelectCategory([''])
    }

    const searchParams = location.search
    const queryStringParams = searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
    const params = queryString.parse(queryStringParams)
    const totalFilters = countFilters(params)
    setCounterFilter(totalFilters)
  }, [keyParamsSearchUrl, pagesProducts])

  const handleFavorite = (id: number) => {
    setProducts(
      products.map((product: any) => {
        if (product.id === id) {
          return { ...product, isFavorite: !product.isFavorite }
        }
        return product
      })
    )
  }

  const handlePage = (selected: number) => {
    setPage(selected)
    history.push({ pathname: `/produtos/${selected}`, search: keyParamsSearchUrl })
  }

  const clearFilters = () => {
    setSearch('')
    history.push({ pathname: '/produtos/1', search: '' })
    setReset(true)
  }

  const handleLogin = () => {
    history.push({ pathname: '/login', state: { from: { pathname: location.pathname } } })
  }

  const handleFilterMobile = () => {
    setFilterModal(false)
    toast.success('Filtros aplicados com sucesso.')
  }

  const countFilters = (params: Record<string, string | string[] | any>): number => {
    let totalFilters = 0

    keyFilter.forEach((key: any) => {
      // Verificar se a chave existe no objeto params
      if (params[key]) {
        totalFilters += Array.isArray(params[key]) ? params[key].length : 1
      }
    })

    return totalFilters
  }

  return (
    <Styles.Wrapper>
      {isBannerFlores && <BannerFlores />}

      <Styles.CategoriesBox>
        <Styles.HeadingCategoryBox>
          <When expr={isMobile}>
            <Heading elementType="h2" fontWeight={400} fontSize="25px" defaultColor="darkGrey">
              <strong>Encontre aqui </strong>
              <strong>
                <span>produtos </span>
              </strong>
              <br />
              para <strong> tratamento com</strong>
              <strong>
                <span> Cannabis medicinal </span>
              </strong>
            </Heading>
          </When>

          <When expr={!isMobile}>
            <Heading elementType="h2" fontWeight={400} fontSize="25px" defaultColor="darkGrey">
              <strong>Encontre produtos </strong>
              <br />
              por <strong>área de tratamento</strong>
            </Heading>
          </When>
        </Styles.HeadingCategoryBox>

        <When expr={!isMobile}>
          <Styles.CategoryList>
            {FilterBadgeMock.map((item: any) => (
              <BadgeTreatmentLine
                type={item.type}
                link={item.link}
                key={item.type}
                id={item.id}
                setSelectCategory={setSelectCategory}
                selectCategory={selectCategory}
                keyParamsSearchUrl={keyParamsSearchUrl}
              />
            ))}
          </Styles.CategoryList>
        </When>
      </Styles.CategoriesBox>

      <Styles.ContentProduct>
        <When expr={!isMobile}>
          <Styles.FilterContainer>
            <Styles.BadgeFilterBox>
              {keyFilter?.length > 0 &&
                keyFilter?.map((item: any) =>
                  keyParamsSearchUrls[item] ? <FilterBadge item={item} key={item} clearFilter={clearFilters} /> : null
                )}
            </Styles.BadgeFilterBox>

            <NewFilter reset={reset} setReset={setReset} />
          </Styles.FilterContainer>
        </When>

        <Styles.ProductListContainer>
          <Styles.ProductFilterContainer>
            <ProductFilter
              isAuth={!!tokenApi}
              search={search}
              setSearch={setSearch}
              count={totalCount}
              openModalFilter={() => setFilterModal(true)}
              countValue={counterFilter}
            />
          </Styles.ProductFilterContainer>
          {selectedValue.type === 'grid' ? (
            <Styles.ProductGrid>
              {loading ? (
                <Styles.LoadingBox>
                  <LoadingContainer loading />
                </Styles.LoadingBox>
              ) : (
                products.length > 0 &&
                products.map((product: any) => (
                  <ProductCard
                    name={product?.translated_name}
                    translated_name={product?.translated_name}
                    key={`${product?.id}-${product?.translated_name}`}
                    real_price={product?.real_price}
                    image={product?.image}
                    isFavorite={product?.isFavorite}
                    handleFavorite={() => handleFavorite(product?.id)}
                    id={product?.id}
                    supplier_id={product?.supplier_id}
                    importProduct={() => addToCart(product?.id, product)}
                    handleLogin={handleLogin}
                    promotional={product?.promotion}
                    product={product}
                  />
                ))
              )}
            </Styles.ProductGrid>
          ) : (
            <Styles.ProductList>
              {loading ? (
                <Styles.LoadingBox>
                  <LoadingContainer loading />
                </Styles.LoadingBox>
              ) : (
                <div>
                  {products?.length > 0 ? (
                    products.map((product: any) => (
                      <ProductGridList
                        key={product?.id}
                        product={product}
                        handleFavorite={() => handleFavorite(product?.id)}
                        importProduct={() => addToCart(product?.id, product)}
                      />
                    ))
                  ) : (
                    <EmptyState title="não encontramos nenhum produto." withAnimation />
                  )}
                </div>
              )}
            </Styles.ProductList>
          )}

          <Styles.PaginateBox>
            {count > 1 && (
              <Pagination
                forcePage={page - 1}
                onPageChange={({ selected }: any) => handlePage(selected + 1)}
                pageCount={count}
              />
            )}
          </Styles.PaginateBox>
        </Styles.ProductListContainer>
      </Styles.ContentProduct>

      <ModalFilter isOpenFilterModal={filterModal}>
        <Styles.ModalFilterContent>
          <div className="buttonContent">
            <h2 className="title-modal">Filtros</h2>
            <IoCloseCircle className="icon-close" onClick={() => setFilterModal(false)} />
          </div>
          <NewFilter reset={reset} setReset={setReset} />
          <Styles.BadgeFilterBoxMobile>
            {keyFilter?.length > 0 &&
              keyFilter?.map((item: any) =>
                keyParamsSearchUrls[item] ? <FilterBadge item={item} key={item} clearFilter={clearFilters} /> : null
              )}
          </Styles.BadgeFilterBoxMobile>
          <div className="clear-filter">
            <Button background="default" onClick={handleFilterMobile}>
              Aplicar filtros
            </Button>
            <Button background="black" isOutlined onClick={clearFilters}>
              limpar filtros
            </Button>
          </div>
        </Styles.ModalFilterContent>
      </ModalFilter>
    </Styles.Wrapper>
  )
}
export default Products
