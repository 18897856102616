import { svgCronica, svgMental } from 'assets/svg'

export const BadgesTreatmentsWellBeing = [
  {
    id: 1,
    title: 'Protocolo de Ansiedade e Insônia',
    icon: svgMental,
    slug: '/suplementos/protocolo-ansiedade-e-insonia',
    type: 'protoco-de-ansiedade-e-insonia'
  },
  {
    id: 2,
    title: 'Protocolo de Dor e Inflamação',
    icon: svgCronica,
    slug: '/suplementos/protocolo-dor-e-inflamacao',
    type: 'protocolo-dor-e-inflamacao'
  }
]
